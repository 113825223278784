import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
   const { site } = useStaticQuery(
      graphql`
         query {
            site {
               siteMetadata {
                  author
                  description
                  title
                  siteUrl
                  keywords
               }
            }
         }
      `
   );

   const keywords = site.siteMetadata.keywords;
   const metaDescription = description || site.siteMetadata.description;
   const defaultTitle = site.siteMetadata?.title;
   const siteURL = site.siteMetadata.siteUrl;

   return (
      <Helmet
         htmlAttributes={{
            lang,
         }}
         title={title}
         titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
         meta={[
            {
               name: `description`,
               content: metaDescription,
            },
            {
               name: `keywords`,
               content: keywords,
            },
            {
               property: `og:title`,
               content: title,
            },
            {
               property: `og:description`,
               content: metaDescription,
            },
            {
               property: `og:type`,
               content: `website`,
            },
            {
               property: 'og:image',
               content: `${siteURL}/og-image.png`,
            },
            {
               name: `twitter:card`,
               content: `summary`,
            },
            {
               name: `twitter:creator`,
               content: site.siteMetadata?.author || ``,
            },
            {
               name: `twitter:title`,
               content: title,
            },
            {
               name: `twitter:description`,
               content: metaDescription,
            },
            {
               property: 'twitter:image',
               content: `${siteURL}/og-image.png`,
            },
         ].concat(meta)}
      />
   );
}

SEO.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
};

SEO.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
};

export default SEO;
